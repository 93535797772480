import { Suspense } from 'react';
import { store } from './store';
import { Provider } from 'react-redux';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { Notification } from 'shared';
import App from './App';
import { RequiredAuth } from './RequiredAuth';
import { RedirectAuth } from './RedirectAuth';
import { RequiredRole, ROLE, userRoles } from './RequiredRole';
import { RedirectBasePath } from './RedirectBasePath';

import { SignIn, ForgotPassword, ResetPassword } from '../pages/Auth';
import { Influencers } from 'pages/Influencers';
import { Campaigns } from 'pages/Campaigns';
import { Profile } from 'pages/Profile';
// import { Notifications } from 'pages/Notifications'
import { Admin } from 'pages/Admin';
import { Campaign } from 'pages/Campaign';
import { SearchesPage } from 'pages/Influencers/SearchesPage';
import { Influencer } from 'pages/Influencer';

// Client
import { CampaignsClient } from 'pages-client/Campaigns';
import { CampaignClient } from 'pages-client/Campaign';
import { ProfileClient } from 'pages-client/Profile';
// import { NotificationsClient } from 'pages-client/Notification'

function Root() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <div className="h-screen overflow-hidden">
                {/* If needed Modal/Notification add <Modal /> and <Notification /> here. Now this containers in the App */}

                <Notification />
                <Outlet />
              </div>
            }
          >
            <Route
              element={
                <RequiredAuth>
                  <Suspense fallback={<div>Loading...</div>}>
                    <App />
                  </Suspense>
                </RequiredAuth>
              }
            >
              <Route path="/" element={<RedirectBasePath />} />

              <Route path="influencers" element={<RequiredRole roles={[ROLE.admin, ROLE.test, ROLE.accoutUser]}>{() => <Influencers />}</RequiredRole>} />
              <Route
                path="influencers/:influencerId"
                element={<RequiredRole roles={[ROLE.admin, ROLE.user, ROLE.test, ROLE.accoutUser]}>{(role: ROLE) => <Influencer role={role} />}</RequiredRole>}
              />
              <Route
                path="influencers/searches/:searchesId"
                element={<RequiredRole roles={[ROLE.admin, ROLE.test, ROLE.accoutUser]}>{() => <SearchesPage />}</RequiredRole>}
              />
              <Route
                path="campaigns"
                element={
                  <RequiredRole roles={[ROLE.admin, ROLE.user, ROLE.test, ROLE.accoutUser]}>
                    {(role) => (userRoles.users.includes(role) ? <CampaignsClient /> : <Campaigns />)}
                  </RequiredRole>
                }
              />
              <Route
                path="campaigns/:campaignId"
                element={
                  <RequiredRole roles={[ROLE.admin, ROLE.user, ROLE.test, ROLE.accoutUser]}>
                    {(role) => (userRoles.users.includes(role) ? <CampaignClient /> : <Campaign />)}
                  </RequiredRole>
                }
              />
              <Route
                path="campaigns/:campaignId/:influencerId"
                element={<RequiredRole roles={[ROLE.admin, ROLE.user, ROLE.test, ROLE.accoutUser]}>{(role) => <Influencer role={role} />}</RequiredRole>}
              />
              {/* <Route
                    path="campaigns/:campaignId/:influencerId"
                    element={
                      <RequiredRole roles={[ROLE.admin, ROLE.user, ROLE.test]}>
                        <InfluencerClient />
                      </RequiredRole>
                    }
                  /> */}
              {/* <Route
                path="notifications"
                element={
                  // ROLE.user
                  <RequiredRole roles={[ROLE.admin, ROLE.test]}>
                    {(role) =>
                      userRoles.users.includes(role) ? (
                        <NotificationsClient />
                      ) : (
                        <Notifications />
                      )
                    }
                  </RequiredRole>
                }
              /> */}
              <Route path="admin" element={<RequiredRole roles={[ROLE.admin, ROLE.accoutUser]}>{() => <Admin />}</RequiredRole>} />
              <Route
                path="admin/:influencerId"
                element={<RequiredRole roles={[ROLE.admin, ROLE.user, ROLE.test]}>{(role) => <Influencer role={role} />}</RequiredRole>}
              />
              <Route
                path="profile"
                element={
                  <RequiredRole roles={[ROLE.admin, ROLE.user, ROLE.test, ROLE.accoutUser]}>
                    {(role) => (userRoles.users.includes(role) ? <ProfileClient /> : <Profile />)}
                  </RequiredRole>
                }
              />
            </Route>

            {/* Authorization */}

            <Route
              path="login"
              element={
                <RedirectAuth>
                  <SignIn />
                </RedirectAuth>
              }
            />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password/:resetLinkCode" element={<ResetPassword />} />

            <Route path="*" element={<div>404 Not Found</div>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default Root;
