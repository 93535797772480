import * as Yup from 'yup';

export const ValidationTypes = {
  // Auth

  login: 'login',
  forgotPassword: 'forgotPassword',
  resetPassword: 'resetPassword',

  // Modals

  CreateCampaign: 'CreateCampaign',
  AdminAddInfluencer: 'AdminAddInfluencer',
  AdminAddNewManager: 'AdminAddNewManager', // AddNewInfluencer in /influencers
  AdminAddNewUser: 'AdminAddNewUser', // AddNewInfluencer in /influencers
  EngagementEdit: 'EngagementEdit',
  ManagementEdit: 'ManagementEdit',
  OutreachEdit: 'OutreachEdit',
  AddToSearch: 'AddToSearch',
  EditSearchInfluencer: 'EditSearchInfluencer',
  SaveChannelList: 'SaveChannelList',
  AddSearchToCampaign: 'AddSearchToCampaign',
  AddToCampaign: 'AddToCampaign',
  ChangePassword: 'ChangePassword',
  AddClient: 'AddClient',
  AdminAddAgency: 'AdminAddAgency',

  // Other

  Profile: 'Profile',
};

export const validationSchema: any = {
  // Auth

  [ValidationTypes.login]: Yup.object().shape({
    email: Yup.string()
      .required()
      .test('lowercase', 'Email must be in lowercase', (value: any) => value === value.toLowerCase())
      .email(),
    password: Yup.string().required(),
    // .min(6, 'Password must be at least 6 characters'),
  }),
  [ValidationTypes.forgotPassword]: Yup.object().shape({
    email: Yup.string()
      .required()
      .test('lowercase', 'Email must be in lowercase', (value: any) => value === value.toLowerCase())
      .email(),
  }),
  [ValidationTypes.resetPassword]: Yup.object().shape({
    password: Yup.string().required(),
    // .min(6, 'Password must be at least 6 characters'),
    c_password: Yup.string()
      .required()
      .oneOf([Yup.ref('password')], 'Passwords must match'),
  }),

  // Modals

  [ValidationTypes.CreateCampaign]: Yup.object().shape({
    campaign_name: Yup.string().required(),
    client_id: Yup.string().required(),
    // product: Yup.string().required(),
    // client_emails: Yup.array().min(1),
    sm_platform: Yup.array().min(1),
    // launch_date: Yup.string().required(), // TODO Date
  }),
  [ValidationTypes.AdminAddInfluencer]: Yup.object().shape({
    name: Yup.string().required(),
    url: Yup.string().required(),
  }),
  [ValidationTypes.AdminAddNewManager]: Yup.object().shape({
    full_name: Yup.string().required(),
    email: Yup.string().required().email(),
    role: Yup.string().required(),
  }),
  [ValidationTypes.AdminAddNewUser]: Yup.object().shape({
    full_name: Yup.string().required(),
    email: Yup.string().required().email(),
    client_id: Yup.string().required(),
    role: Yup.string().required(),
  }),
  [ValidationTypes.EngagementEdit]: Yup.object().shape({
    name: Yup.string().required(),
    url: Yup.string().required(),
    status: Yup.string().required(),
    format: Yup.string().required(),
    price: Yup.string().required(),
    cpm: Yup.string().required(),
  }),
  [ValidationTypes.AddToSearch]: Yup.object().shape({
    name: Yup.string().required(),
    search: Yup.string().required(),
  }),
  [ValidationTypes.EditSearchInfluencer]: Yup.object().shape({
    name: Yup.string().required(),
  }),
  // One field from list required
  [ValidationTypes.SaveChannelList]: Yup.object().shape(
    {
      name: Yup.string().when('list_id', {
        is: (list_id: string) => !list_id,
        then: Yup.string().required(),
      }),
      list_id: Yup.string().when('name', {
        is: (name: string) => !name,
        then: Yup.string().required(),
      }),
    },
    [
      ['name', 'list_id'],
      ['list_id', 'name'],
    ]
  ),
  [ValidationTypes.ManagementEdit]: Yup.object().shape({
    name: Yup.string().required(),
    url: Yup.string().required(),
    status: Yup.string().required(),
    format: Yup.string().required(),
    code: Yup.string().required(),
    assets_status: Yup.string().required(),
    draft_date: Yup.string().required(),
    public_date: Yup.string().required(),
  }),
  [ValidationTypes.OutreachEdit]: Yup.object().shape({
    name: Yup.string().required(),
    url: Yup.string().required(),
    status: Yup.string().required(),
    subscribers: Yup.string().required(),
    average_views: Yup.string().required(),
    country: Yup.string().required(),
    email: Yup.string().required().email(),
    agency: Yup.string().required(),
    agency_name: Yup.string().required(),
  }),
  [ValidationTypes.ChangePassword]: Yup.object().shape({
    old_password: Yup.string().required(),
    new_password: Yup.string().required(),
  }),
  [ValidationTypes.AddSearchToCampaign]: Yup.object().shape({
    name: Yup.string().required(),
  }),
  [ValidationTypes.AddToCampaign]: Yup.object().shape({
    campaign_id: Yup.string().required(),
  }),

  [ValidationTypes.AddClient]: Yup.object().shape({
    client_name: Yup.string().required(),
    client_type: Yup.string().required(),
  }),
  [ValidationTypes.AdminAddAgency]: Yup.object().shape({
    agency_name: Yup.string().required(),
    agency_website: Yup.string().required().url(),
    agency_country: Yup.array().required(),
    agency_type: Yup.string().required(),
    agency_focus: Yup.string().required(),
  }),

  // Other

  [ValidationTypes.Profile]: Yup.object().shape({
    full_name: Yup.string().required(),
    email: Yup.string()
      .required()
      .test('lowercase', 'Email must be in lowercase', (value: any) => value === value.toLowerCase())
      .email(),
  }),
};
