import { FC } from 'react';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { Menu } from 'shared';
import { createUserThunk, fetchUsersThunk, selectUsers } from '../reducer';
import { PeopleManagementTable } from './PeopleManagementTable';
import { showModal } from 'components/Modal/reducer';
import { ModalTypes } from 'components/Modal';
import { SimpleSearch } from 'components/SimpleSearch';
import { PlusIcon } from '@heroicons/react/24/outline';
import { ROLE, UserHasRequiredRole } from '../../../app/RequiredRole';

export const PeopleManagement: FC = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsers);

  const isEmpty = !users.length;

  useLegacyEffect(() => {
    dispatch(fetchUsersThunk());
  }, []);

  return (
    <>
      <SimpleSearch
        className="py-4"
        placeholder="Search by peope, role"
        onChange={(value: string) => {
          dispatch(fetchUsersThunk(value));
        }}
      />
      <div className="flex flex-col overflow-hidden p-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl leading-[42px] font-semibold">People management ({users.length})</h1>
          <div className="flex items-center space-x-4">
            {/* <Menu
              className="flex items-center justify-center"
              itemsClass="top-[2.7rem] !left-0" // min-w-[154px]
              optionClass="!px-4"
              items={[
                {
                  label: 'Excel',
                  onClick: () => {},
                },
                {
                  label: 'CSV',
                  onClick: () => {},
                },
              ]}
            >
              {({ open }) => (
                <button
                  type="button"
                  className={cn(
                    'inline-flex items-center justify-center w-[42px] h-[42px] rounded-[4px] border border-transparent shadow-sm focus:outline-none focus:ring-0',
                    open ? 'bg-blue-b1 text-white' : 'bg-white text-blue-b1'
                  )}
                >
                  <ExportIcon pathFill={open ? colors.white.default : colors.blue.b1} aria-hidden="true" />
                </button>
              )}
            </Menu> */}
            <Menu
              className="flex items-center justify-center"
              itemsClass="w-full top-[2.7rem] !left-0" // min-w-[154px]
              optionClass="!px-4"
              items={[
                {
                  label: 'Manager',
                  disabled: !UserHasRequiredRole([ROLE.admin]),
                  onClick: () =>
                    dispatch(
                      showModal({
                        type: ModalTypes.AdminAddNewManager,
                        params: {
                          handleSave: async (data: any) => await dispatch(createUserThunk(data)),
                        },
                      })
                    ),
                },
                {
                  label: 'User',
                  onClick: () =>
                    dispatch(
                      showModal({
                        type: ModalTypes.AdminAddNewUser,
                        params: {
                          handleSave: async (data: any) => await dispatch(createUserThunk(data)),
                        },
                      })
                    ),
                },
              ]}
            >
              {({ open }) => (
                <button
                  type="button"
                  className="inline-flex items-center h-[42px] rounded-[4px] border border-transparent bg-blue-b1 px-2.5 uppercase text-sm leading-6 font-medium text-gray-g1 ring-0 ring-offset-0 hover:bg-blue-b1 focus:outline-none"
                >
                  <PlusIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                  <p className="mr-1.5">add new</p>
                </button>
              )}
            </Menu>
          </div>
        </div>

        {isEmpty ? null : <PeopleManagementTable users={users} />}
      </div>
    </>
  );
};
