import { FC, useMemo } from 'react';
import cn from 'classnames';

import { ModalState } from '../reducer';
import { ModalHeader } from '../index';
import { ClipLoader, Form, Input, Label, RSelect, RSelectStyles } from '../../../shared';
import { ValidationTypes } from '../../../utils/validation';
import { Controller } from 'react-hook-form';
import { StylesConfig } from 'react-select';
import { ROLE, UserHasRequiredRole } from '../../../app/RequiredRole';

interface IAddClient extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}

const selectStyles: StylesConfig<any, false> = {
  ...RSelectStyles,
  control: (styles, props) => ({
    ...(RSelectStyles?.control ? RSelectStyles?.control(styles, props) : {}),
    minHeight: '48px !important',
  }),
};

const AddClient: FC<IAddClient> = ({ loading, params, startLoading, stopLoading, hideModal }) => {
  const defaultValues = useMemo(
    () => ({
      client_name: params?.client?.client_name || '',
      client_type: params?.client?.client_type || 'regular',
    }),
    [params?.client]
  );

  const clientTypes = [
    {
      value: 'regular',
      label: 'Regular client',
    },
    {
      value: 'saas',
      label: 'SAAS client',
    },
  ];

  const isUpdate = !!params?.client?.client_name;
  const hasAdminRole = UserHasRequiredRole([ROLE.admin]);

  return (
    <div className="flex h-full flex-col">
      <ModalHeader title={(isUpdate ? 'Update' : 'Add') + ' client'} close={hideModal} />

      <Form
        className="flex-1 space-y-5 p-6 pt-0 sm:p-8 sm:pt-0"
        defaultValues={defaultValues}
        validationType={ValidationTypes.AddClient}
        onSubmit={(values) => params.handleSave(values).then(hideModal)}
      >
        {({ register, control, formState: { errors, isSubmitting } }) => (
          <>
            <div>
              <Label htmlFor="client_name" label="Company Name" error={errors.client_name} />

              <div className="mt-0.5">
                <Input id="client_name" type="text" placeholder="Company Name" error={errors.client_name} {...register('client_name')} />
              </div>
            </div>

            {hasAdminRole && (
              <div>
                <Label htmlFor="client_id" label="Client type" error={errors.client_type} />
                <div className="mt-0.5">
                  <Controller
                    name="client_type"
                    control={control}
                    render={(props: any) => {
                      const { field } = props;
                      return (
                        <RSelect
                          {...field}
                          styles={selectStyles}
                          placeholder="Not Selected"
                          options={clientTypes}
                          error={errors.client_type}
                          components={{
                            ClearIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                          value={clientTypes.find((option) => option.value === field.value) || null}
                          onChange={(option: any) => {
                            field.onChange(option.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </div>
            )}

            <div className="grid grid-cols-2 gap-8">
              <button
                type="button"
                className="flex w-full h-12 items-center uppercase justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none focus:ring-0"
                onClick={hideModal}
              >
                Cancel
              </button>

              <button
                type="submit"
                className={cn(
                  'flex w-full h-12 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0',
                  { disabled: isSubmitting }
                )}
                disabled={isSubmitting}
              >
                <p>Save</p>

                <ClipLoader loading={isSubmitting} size={20} styles={{ borderWidth: '2px' }} />
              </button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default AddClient;
