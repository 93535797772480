import { useAppSelector } from 'hooks/store';
import { selectRole } from 'pages/Auth/reducer';

export enum ROLE {
  admin = 'admin',
  user = 'user',
  test = 'test',
  accoutUser = 'account_user',
}

export const userRoles: any = {
  admins: [ROLE.admin, ROLE.test],
  users: [ROLE.user],
};

export const RequiredRole = ({ children, roles }: { children: (role: ROLE) => JSX.Element; roles: Array<ROLE> }) => {
  const role = useAppSelector(selectRole);

  const userHasRequieredRole = Boolean(role && roles.includes(role));

  if (!userHasRequieredRole) {
    return <div>Access Denied</div>;
  }

  return children(role);
};

/**
 * Return true if current user has at least one role
 * @param roles ROLE[]
 * @returns boolean
 */
export const UserHasRequiredRole = (roles: ROLE[]): boolean => {
  const role = useAppSelector(selectRole);
  return roles.includes(role);
};
