import { FC, useMemo } from 'react';
import { useFlexLayout, useSortBy, useTable } from 'react-table';
import cn from 'classnames';
import { PlusIcon } from '@heroicons/react/24/outline';

import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { fetchClientsThunk, selectClients } from '../../../pages/Admin/reducer';

import { SimpleSearch } from '../../../components/SimpleSearch';
import { TableHeaderSort } from '../../../shared';
import { showModal } from '../../../components/Modal/reducer';
import { ModalTypes } from '../../../components/Modal';
import { createClientThunk, deleteClientThunk, updateClientThunk } from '../reducer';
import { DeleteIcon, EditIcon } from 'assets/icons';
import { UserHasRequiredRole, ROLE } from '../../../app/RequiredRole';

const ClientsTab: FC = () => {
  const dispatch = useAppDispatch();
  const clients = useAppSelector(selectClients);

  const handleAddClientOnClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(
      showModal({
        type: ModalTypes.AddClient,
        params: {
          handleSave: async (data: any) => await dispatch(createClientThunk(data)),
        },
      })
    );
  };

  return (
    <>
      <SimpleSearch
        placeholder="Search by company name"
        size="lg"
        iconPlace="right"
        onChange={(value: string) => dispatch(fetchClientsThunk({ query: value }))}
      />

      <div className="flex flex-1 flex-col p-6 bg-gray-g1 overflow-hidden">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl leading-[42px] font-semibold">Clients ({clients.length})</h1>

          <div className="flex items-center space-x-4">
            <button
              type="button"
              className="inline-flex items-center h-[42px] rounded-[4px] border border-transparent bg-blue-b1 px-2.5 uppercase text-sm leading-6 font-medium text-gray-g1 hover:bg-blue-b1 focus:outline-none focus:ring-0"
              onClick={handleAddClientOnClick}
            >
              <PlusIcon className="mr-2 h-5 w-5" aria-hidden="true" />
              <p className="mr-1.5">Add client</p>
            </button>
          </div>
        </div>

        <ClientsTable clients={clients} />
      </div>
    </>
  );
};

interface IClientsTable {
  clients: object[];
}

const ClientsTable: FC<IClientsTable> = ({ clients }) => {
  const dispatch = useAppDispatch();
  const hasAdminRole = UserHasRequiredRole([ROLE.admin, ROLE.test]);

  const columns = useMemo(() => {
    let companies = [];
    companies.push({
      Header: 'Company Name',
      accessor: 'client_name',
    });

    hasAdminRole &&
      companies.push({
        Header: 'Company Type',
        accessor: 'client_type',
        width: 50,
        Cell: ({ value }: any) => (
          <div className={cn('flex items-center h-6 px-2.5 rounded-[4px] text-sm text-black-b1', value === 'saas' ? 'bg-orange-300' : 'bg-gray-g1')}>
            {value.toUpperCase()}
          </div>
        ),
      });

    companies.push({
      Header: '',
      accessor: '_actions',
      width: 12,
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <>
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              dispatch(
                showModal({
                  type: ModalTypes.AddClient,
                  params: {
                    client: row.original,
                    handleSave: async (data: any) => await dispatch(updateClientThunk({ client_id: row.original.client_id, data: data })),
                  },
                })
              );
            }}
          >
            <EditIcon />
          </button>

          <button
            className="ml-4"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              dispatch(
                showModal({
                  type: ModalTypes.DeleteConfirmation,
                  params: {
                    name: row.original.client_name,
                    handleDelete: async () => await dispatch(deleteClientThunk(row.original.client_id)),
                  },
                })
              );
            }}
          >
            <DeleteIcon />
          </button>
        </>
      ),
    });

    return companies;
  }, []);

  const table = useTable(
    {
      columns: columns,
      data: clients,
    },
    useSortBy,
    useFlexLayout
  );

  return (
    <div className="mt-5 flex flex-1 flex-col justify-between overflow-hidden">
      <div className="mb-2 flex flex-col overflow-hidden">
        <div className="flex flex-col min-w-full align-middle overflow-hidden">
          <div className="overflow-auto">
            <table {...table.getTableProps()} className="min-w-full w-full rounded-[4px]">
              <thead className="sticky top-0 z-30 bg-gray-g1">
                {table.headerGroups.map((headerGroup: any) => (
                  <tr {...headerGroup.getHeaderGroupProps()} className="py-1.5 pl-6">
                    {headerGroup.headers.map((column: any, hidx: number) => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        className={cn(
                          'text-left text-xs font-normal text-black-b1',
                          typeof column.render('Header') === 'string' ? 'truncate' : '',
                          hidx === 0 ? 'pl-0 pr-2' : 'px-2',
                          column?.headerClassName || ''
                        )}
                      >
                        <div title={column.header} className="h-[18px] flex items-center space-x-3 cursor-pointer">
                          <p>{column.render('Header')}</p>

                          <TableHeaderSort column={column} />
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...table.getTableBodyProps()} className="space-y-3">
                {table.rows.map((row: any) => {
                  table.prepareRow(row);

                  return (
                    <tr {...row.getRowProps()} className="relative group pl-6 rounded-[4px] bg-white cursor-pointer hover:shadow-s1">
                      {row.cells.map((cell: any, cidx: number) => (
                        <td
                          {...cell.getCellProps()}
                          className={cn(
                            'relative flex items-center h-[58px] whitespace-nowrap text-sm leading-6 font-normal text-black-b1',
                            cidx === 0 ? 'pl-0 pr-2' : 'px-2',
                            cell.column?.className || ''
                          )}
                        >
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsTab;
